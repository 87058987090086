import { useState } from 'react';
import type { Translate } from '@cms/i18n';

import { useTranslation } from '@cms/i18n/client';
import {
    News,
    NewsCategory,
    NewsCategoryService,
    NewsService,
    NewsStatus,
    Pagination,
    Scope,
    Tag,
    TagService,
} from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';
import { DefaultServerSideProps } from '@common/types/DefaultProps';
import { ItemsPerPage } from '@common/types/ItemsPerPage';
import { IntroBlock, IntroBlockProps } from '@web/molecules/IntroBlock';
import { MatchTicker } from '@web/molecules/MatchTicker';
import { NewsList } from '@web/molecules/NewsList';
import { DisplayType } from '@web/molecules/NewsList/NewsList.types';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { Route } from '@web/routing';
import { getIncrementalNextPage } from '@web/utils/getIncrementalNextPage';

export interface State {
    currentPage: number;
    pagination?: Pagination;
    generalVideos: News[];
    categoryVideos: News[][];
    tag?: Tag;
    newsCategory?: NewsCategory;
}

export interface Props {
    state: State;
}

export const fetchCategories = async (
    contextData: ContextData,
    isClientSide: boolean,
): Promise<NewsCategory[] | undefined> => {
    const { data } = await ApiService({
        contextData,
        isClientSide,
        service: NewsCategoryService,
    }).listNewsCategoryByDomainId({ path: { contextID: contextData.context.id }, query: { isMedia: true } });

    return data;
};

export const fetchTag = async (
    slugOrID: string,
    contextData: ContextData,
    isClientSide: boolean,
): Promise<Tag | undefined> => {
    const { data } = await ApiService({
        contextData,
        isClientSide,
        service: TagService,
    }).getBySlugTagByDomainId({ path: { contextID: contextData.context.id, slugOrID: slugOrID } });

    return data;
};

export const fetchVideos = async (
    contextData: ContextData,
    currentPage: number = 1,
    isClientSide: boolean,
    newsCatID?: number,
    tagID?: number,
) => {
    const perPage = newsCatID ? ItemsPerPage.SMALL : ItemsPerPage.STANDARD;
    const { nextPage, nextPerPage } = getIncrementalNextPage(currentPage, perPage);

    const { data } = await ApiService({ contextData, isClientSide, service: NewsService }).listNewsByDomainId(
        {
            path: { contextID: contextData.context.id },
            query: {
                experimentGroup: contextData.experimentGroup,
                scope: Scope.PUBLIC,
                perPage: nextPerPage,
                page: nextPage,
                checkNextPage: true,
                tagID: tagID,
                newsStatus: NewsStatus.PUBLISHED,
                isMedia: true,
                newsCatID: newsCatID,
            },
        },
    );

    return data;
};

export const fetchCategoryVideos = async (
    contextData: ContextData,
    categories: NewsCategory[] | undefined,
    tagID?: number,
) => {
    if (!categories) {
        return [];
    }

    const result: News[][] = [];
    const videoPromises = categories.map((category) => {
        return fetchVideos(contextData, 1, false, category.id, tagID);
    });

    const [...videos] = await Promise.all([...videoPromises]);
    videos.forEach((videosResponse) => {
        if (videosResponse?.data?.length) result.push(videosResponse.data);
    });
    return result;
};

const getIntroBlockData = (
    platformID: string,
    newsCategory: NewsCategory | undefined,
    tagName: string | undefined,
    __videos: Translate,
): IntroBlockProps => {
    const superTitle = platformID === 'gp' ? '' : __videos`latest-videos`;

    let title = platformID === 'gp' ? __videos`videos` : __videos`football-videos`;
    if (newsCategory) title = `${newsCategory.name} ${__videos('videos')}`;
    if (tagName) title = `${tagName} ${__videos('videos')}`;

    const isInverted = platformID !== 'gp';

    return { superTitle, title, description: '', isInverted };
};

export const VideoCategoryOverview = ({
    contextData,
    state: initialState,
}: Props & DefaultServerSideProps) => {
    const __videos = useTranslation('videos').t;
    const __meta = useTranslation('meta').t;

    const [state, setState] = useState<State>(initialState);

    const onReadMoreClick = async (): Promise<void> => {
        const newState: State = { ...state, currentPage: state.currentPage + 1 };

        const data = await fetchVideos(contextData, newState.currentPage, true, undefined, state.tag?.tagID);
        data?.data?.forEach((video: News) => {
            state.generalVideos.push(video);
        });

        newState.generalVideos = state.generalVideos || [];
        newState.pagination = data?.pagination;

        setState(newState);
    };

    return (
        <>
            <MetaHead
                title={state.tag?.tagMetaTitle || state.tag?.title || ''}
                description={__meta(`category-videos.description.${contextData.context.subject}`, {
                    domainName: contextData.context.name,
                    category: state.tag?.tagMetaTitle || state.tag?.title || '',
                })}
                parents={[Route.VideoOverview]}
                isCopyrighted={false}
            />
            <div className="main-content">
                <IntroBlock
                    {...getIntroBlockData(
                        contextData.platform.id,
                        state.newsCategory,
                        state.tag?.title,
                        __videos,
                    )}
                />
                <NewsList
                    skipDivider={true}
                    items={state.generalVideos}
                    pagination={state.pagination}
                    onReadMoreClick={onReadMoreClick}
                    displayType={DisplayType.blocks}
                />
                {state.categoryVideos?.map((videosByCategory, index) => {
                    const newsCategory = videosByCategory[0].category;
                    const newsCatNameSlug = newsCategory?.slug;
                    return (
                        <NewsList
                            key={`video-list-${index}`}
                            title={newsCategory?.name}
                            titleTag="h2"
                            items={videosByCategory}
                            displayType={DisplayType.carrousel}
                            moreArticlesLink={newsCatNameSlug}
                        />
                    );
                })}
            </div>
            <MatchTicker />
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
