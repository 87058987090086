import { ReactElement } from 'react';
import clsx from 'clsx';

import { BrandedHeading, BrandedHeadingAccentColor } from '@web/atoms/BrandedHeading';

import { MainDropdown } from '../MainDropdown';
import { Item } from '../MainDropdown/types';
import { useIntroBlockThemeConfig } from './IntroBlock.config';

import styles from './IntroBlock.module.scss';

export interface Props {
    superTitle?: string;
    title: string | ReactElement;
    titleAccentColor?: BrandedHeadingAccentColor;
    description?: string;
    isInverted?: boolean;
    dropDownItems?: Item[];
    onChangeDropdown?: (selection: Item) => Promise<void>;
}
export const IntroBlock = ({
    superTitle,
    title,
    titleAccentColor,
    description,
    isInverted,
    dropDownItems = [],
    onChangeDropdown,
}: Props) => {
    const themeConfig = useIntroBlockThemeConfig();
    return (
        <div className={clsx(styles['IntroBlock'], isInverted && styles['IntroBlock--inverted'])}>
            {themeConfig.showSuperTitle ? <h3 className={styles['super-title']}>{superTitle}</h3> : null}
            <BrandedHeading
                className={styles['title']}
                as="h1"
                accentColor={titleAccentColor}
                variant={isInverted ? undefined : themeConfig.brandedHeadingVariant}
                typography={themeConfig.brandedHeadingTypography}
            >
                {title}
            </BrandedHeading>
            {dropDownItems?.length ? (
                <span className={styles['main-drop-holder']}>
                    <MainDropdown onChangeDropdown={onChangeDropdown} items={dropDownItems} />
                </span>
            ) : null}
            {description ? (
                <>
                    <hr />
                    <p className={styles['description']}>{description}</p>
                </>
            ) : null}
        </div>
    );
};
