import { Typography } from '@common/types/Typography';
import { BrandedHeadingVariant, Props as BrandedHeadingProps } from '@web/atoms/BrandedHeading';
import { ThemeConfig, useThemeConfig } from '@web/hooks/useThemeConfig';

export interface IntroBlockThemeConfig {
    brandedHeadingVariant: NonNullable<BrandedHeadingProps['variant']>;
    brandedHeadingTypography?: BrandedHeadingProps['typography'];
    showSuperTitle: boolean;
}

const THEME_CONFIG: ThemeConfig<IntroBlockThemeConfig> = {
    defaults: { brandedHeadingVariant: BrandedHeadingVariant.BORDER_BOTTOM, showSuperTitle: true },
    byPlatform: { vi: { brandedHeadingTypography: Typography.HEADING_MEDIUM, showSuperTitle: false } },
};

export const useIntroBlockThemeConfig = () => {
    return useThemeConfig(THEME_CONFIG);
};
